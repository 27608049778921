import React from 'react';

interface ILink {
  text: string;
  href: string;
}

const DrawerLinks: React.FunctionComponent = () => {
  const links: ILink[] = [
    { text: 'MINT', href: '#mint' },
    { text: 'EARN', href: '#earn' },
    { text: 'ROADMAP', href: '#road-map' },
    { text: 'TEAM', href: '#team' },
  ];

  return (
    <div className="flex items-start flex-col my-8 mx-4">
      {links.map(item => (
        <a
          className="h-fit text-darkblue font-bold mx-4 hover:scale-125 duration-100 my-4"
          key={item.href}
          href={item.href}
        >
          {item.text}
        </a>
      ))}
    </div>
  );
};

export default DrawerLinks;
