import React from 'react';
import Card from 'components/global/card';

const TeamSection: React.FunctionComponent = () => {
  const data = ['artist', 'developer', 'community-manager', 'developer-2'];

  const getImageSrc = (name: string) => `images/${name}.svg`;

  return (
    <div id="team" className="my-24 w-full mx-auto md:max-w-[524px] xl:max-w-[1144px]">
      <p className="text-darkblue font-bold text-[32px] leading-[42.34px] md:text-[48px] md:leading-[63.5px]">
        TEAM MEMBERS
      </p>
      <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-x-4 md:gap-y-8 xl:gap-8 mt-8">
        {data.map(team => (
          <Card
            className="flex items-center md:block md:max-w-[254px] xl:max-w-[262px]"
            hasShadow
            key={team}
          >
            <img
              className="w-[164px] my-4 mx-4 md:mx-auto md:w-[222px] xl:w-[216px] "
              src={getImageSrc(team)}
              alt={team}
            />
            <p className="text-[20px] text-center my-8 md:text-[24px] leading-[26.46px] md:leading-[31.75px] text-mediumblue font-medium capitalize">
              {team.replace('-', ' ')}
            </p>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
