import React, { useState } from 'react';
import clsx from 'clsx';
import Drawer from 'react-modern-drawer';
import { useMediaQuery } from 'react-responsive';
import MintButton from 'components/global/mint-button';
import Logo from 'components/icons/Logo';
import MenuIcon from 'components/icons/MenuIcon';
import Links from 'components/layouts/navbar/components/Links';
import 'react-modern-drawer/dist/index.css';
import DrawerLinks from './components/DrawerLinks';

const Navbar: React.FunctionComponent = () => {
  const [open, setOpen] = useState(false);

  const isMD = useMediaQuery({ query: '(min-width: 850px)' });

  const handleDrawerStatus = () => {
    setOpen(prev => !prev);
  };
  return (
    <div
      className={clsx(
        'p-2 flex items-center justify-center md:gap-0 sticky top-0 right-0 left-0 w-full h-[96px] bg-background z-50',
      )}
    >
      <div className={clsx('flex items-center w-full max-w-[1144px]', { 'justify-between': isMD })}>
        {!isMD && (
          <button onClick={handleDrawerStatus} className="mr-6">
            <MenuIcon />
          </button>
        )}
        <Logo />
        {isMD && <Links />}
        {!isMD && (
          <Drawer open={open} onClose={handleDrawerStatus} direction="left">
            <DrawerLinks />
          </Drawer>
        )}
        {isMD && <MintButton small />}
      </div>
    </div>
  );
};
export default Navbar;
