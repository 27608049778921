import React from 'react';

const ArrowDownLongIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="16"
      height="790"
      viewBox="0 0 16 1159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8 16L7.99999 177.838L7.99998 408.576L7.99997 741.866L7.99996 903.169L7.99995 1159"
        stroke="#1B92A6"
        strokeWidth="4"
        strokeDasharray="25 25"
      />
      <circle cx="8" cy="8" r="8" fill="#1B92A6" />
      <circle cx="8" cy="166" r="8" fill="#1B92A6" />
      <circle cx="8" cy="418" r="8" fill="#1B92A6" />
      <circle cx="8" cy="766" r="8" fill="#1B92A6" />
      <circle cx="8" cy="954" r="8" fill="#1B92A6" />
      <circle cx="8" cy="1151" r="8" fill="#1B92A6" />
    </svg>
  );
};
export default ArrowDownLongIcon;
