import React from 'react';
import EqualIcon from 'components/icons/EqualIcon';
import PlusIcon from 'components/icons/PlusIcon';
import QuestionIcon from 'components/icons/QuestionIcon';
import './_styles.scss';

const EarnSection: React.FunctionComponent = () => {
  const imageSrc = 'images/glassed-bear.svg';
  const ticketSrc = 'images/ticket.svg';
  return (
    <div id="earn" className="earn-section max-w-[912px] mx-auto my-24">
      <div className="earn-section__box w-full max-h-[836px] h-full">
        <img src={imageSrc} alt="Glassed Bear" />
        <span className="font-medium text-darkblue mt-2 mb-4">Gen0 Travel Bear</span>
        <PlusIcon />
        <img className="xl:mt-4 xl:mb-2" src={ticketSrc} alt="Ticket" />
        <span className="font-medium text-darkblue mt-2 mb-4">Plane Ticket</span>
        <EqualIcon />
        <QuestionIcon />
      </div>
      <div className="earn-section__text">
        <p className="text-darkblue font-bold earn-section__title">
          PASSIVELY EARN <br /> $MILES
        </p>
        <p className="text-mediumblue font-medium earn-section__desc">
          1 $MILES = 1 $MILES
          <br />
          Earn our ERC-20 token $MILES just by holding your Genesis Travel Bear in your wallet, no
          staking required!
          <br />
          <br />
          Use $MILES to purchase plane tickets to visit your family members in different regions
          across the metaverse. Collecting these family members will be key to future integration
          into the metaverse.
        </p>
      </div>
    </div>
  );
};
export default EarnSection;
