import React from 'react';

const FirstArrowIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="352"
      height="94"
      viewBox="0 0 352 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M345 9.01949C343.168 9.0065 341.334 9 339.499 9C220.55 9 108.038 36.3287 8 85"
          stroke="#1B92A6"
          strokeWidth="8"
          strokeDasharray="25 25"
        />
        <circle cx="8" cy="86" r="8" fill="#1B92A6" />
        <circle cx="344" cy="8" r="8" fill="#1B92A6" />
      </g>
    </svg>
  );
};
export default FirstArrowIcon;
