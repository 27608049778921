import React from 'react';

const ArrowDownIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="16"
      height="968"
      viewBox="0 0 16 968"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M8 22L8.00004 958"
        stroke="#1B92A6"
        strokeWidth="6"
        strokeDasharray="25 25"
      />
      <circle cx="8" cy="8" r="8" fill="#1B92A6" />
      <circle cx="8" cy="164" r="8" fill="#1B92A6" />
      <circle cx="8" cy="352" r="8" fill="#1B92A6" />
      <circle cx="8" cy="636" r="8" fill="#1B92A6" />
      <circle cx="8" cy="792" r="8" fill="#1B92A6" />
      <circle cx="8" cy="960" r="8" fill="#1B92A6" />
    </svg>
  );
};
export default ArrowDownIcon;
