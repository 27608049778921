import React from 'react';
import Logo from 'components/icons/Logo';
import './_styles.scss';

const FirstViewSection: React.FunctionComponent = () => {
  const imageSrc = 'images/first-view.gif';
  return (
    <div className="first-view-section flex justify-center mx-auto">
      <img className="max-w-full object-cover" src={imageSrc} alt="First View" />
      <div className="first-view-section__text">
        <Logo />
        <p className="text-mediumblue">A Collection of 7370 bears</p>
      </div>
    </div>
  );
};
export default FirstViewSection;
