import { ComponentType } from 'react';
import FirstArrowIcon from 'components/icons/FirstArrowIcon';
import FourthArrowIcon from 'components/icons/FourthArrowIcon';
import SecondArrowIcon from 'components/icons/SecondArrowIcon';
import ThirdArrowIcon from 'components/icons/ThirdArrowIcon';

export interface IRoadMap {
  key: string;
  items: string[];
  icon: ComponentType<any>;
  className?: string;
}

export const roadmapItems: IRoadMap[] = [
  { key: '1', icon: FirstArrowIcon, items: ['7370 Genesis Travel Bears come out of hibernation'] },
  {
    key: '2',
    icon: SecondArrowIcon,
    items: [
      'Begin earning 10 $MILES per day',
      'Spend 600 $MILES to purchase a plane ticket and fly to your family members',
    ],
    className: 'mt-[90px]',
  },
  {
    key: '3',
    icon: ThirdArrowIcon,
    items: [
      'IRL Trip Giveaways',
      'Merch drop + Geolocation prizes',
      'Be the first ones to send us pictures from chosen IRL locations wearing your awesome Travel Bears merch and win many awesome prizes!',
    ],
    className: 'mt-[90px]',
  },
  {
    key: '4',
    icon: FourthArrowIcon,
    items: ['Send out your family members to hunt for special goodies'],
    className: 'mt-[122px]',
  },
  {
    key: '5',
    icon: FirstArrowIcon,
    items: [
      'Travel Bears enters the metaverse with our VX collection. These will be used in future metaverse games!',
    ],
    className: 'mt-[88px]',
  },
];
