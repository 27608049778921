import React from 'react';

const ThirdArrowIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="352"
      height="152"
      viewBox="0 0 352 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <circle cx="8" cy="144" r="8" fill="#1B92A6" />
        <circle cx="344" cy="8" r="8" fill="#1B92A6" />
        <path
          d="M344 7C184.051 39.3326 109.116 67.7397 8 144"
          stroke="#1B92A6"
          strokeWidth="8"
          strokeDasharray="25 25"
        />
      </g>
    </svg>
  );
};
export default ThirdArrowIcon;
