import React from 'react';
import MintButton from 'components/global/mint-button';
import './_styles.scss';

const MintSection: React.FunctionComponent = () => {
  return (
    <div id="mint" className="mint-section mx-auto w-full my-12 max-w-[1144px]">
      <p className="text-darkblue font-bold">Mint Genesis Travel Bears</p>
      <MintButton className="w-[215px] h-[60px]" withShadow />
    </div>
  );
};
export default MintSection;
