import React from 'react';

const CloseIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="132"
      height="132"
      viewBox="0 0 132 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.215 0.58594C111.434 -0.195314 110.168 -0.195313 109.387 0.585941L65.2897 44.7063L22.6134 2.00756C21.8325 1.22631 20.5665 1.22631 19.7857 2.00756L0.585632 21.2177C-0.195211 21.999 -0.195211 23.2656 0.585633 24.0469L43.262 66.7456L2.07593 107.953C1.29509 108.735 1.29509 110.001 2.07594 110.783L21.276 129.993C22.0568 130.774 23.3228 130.774 24.1037 129.993L65.2897 88.785L107.897 131.414C108.677 132.196 109.943 132.196 110.724 131.414L129.924 112.204C130.705 111.423 130.705 110.156 129.924 109.375L87.3174 66.7456L131.415 22.6253C132.195 21.844 132.195 20.5773 131.415 19.7961L112.215 0.58594Z"
        fill="#C76E46"
      />
    </svg>
  );
};
export default CloseIcon;
