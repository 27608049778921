import React from 'react';
import './_styles.scss';

const TreasureHunt: React.FunctionComponent = () => {
  const imageSrc = 'images/bear.svg';
  return (
    <div
      className="bg-white flex items-center justify-center"
      style={{
        boxShadow: '0 4px 10px 4px rgba(222, 226, 227, 0.25)',
        margin: '0 -12px',
        width: 'calc(100% + 24px)',
      }}
    >
      <div className="treasure-section w-full max-w-[1144px]">
        <div className="flex flex-col">
          <p className="treasure-section__title text-darkblue font-bold">TREASURE HUNT</p>
          <p className="treasure-section__desc text-mediumblue">
            Stake your family member Travel Bears to send them out on the hunt for special goodies!
            They’ll do their best to bring you back some great prizes including merch, travel
            packages, VX NFTs, and much more
          </p>
        </div>
        <img src={imageSrc} alt="Bear" />
      </div>
    </div>
  );
};
export default TreasureHunt;
