/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './_styles.scss';
import { useMediaQuery } from 'react-responsive';

const handleDragStart = (e: { preventDefault: () => any }) => e.preventDefault();

const data = Array(2).fill(['bear', 'glassed-bear']).flat();

const getImageSrc = (name: string) => `images/${name}.svg`;

const items = data.map((item, index) => (
  <img
    className="mx-auto max-w-full"
    key={`${item}-${index}`}
    src={getImageSrc(item)}
    onDragStart={handleDragStart}
    alt="Bears"
  />
));

const TravelBears: React.FunctionComponent = () => {
  const small = useMediaQuery({
    query: '(min-width: 500px)',
  });
  const medium = useMediaQuery({
    query: '(min-width: 900px)',
  });
  return (
    <div className="bg-white my-24 mx-[-12px] py-12 travel-bear-section">
      <div className="px-[0.5rem] xl:px-0 mx-auto max-w-[1144px]">
        <p className="text-darkblue text-[32px] lg:text-[48px] leading-[63.5px] font-bold">
          TRAVEL BEARS
        </p>
        <p className="text-mediumblue mt-4 mb-12 text-[16px] md:text-[24px] leading-[2rem] font-medium max-w-[680px]">
          A family of 7370 bears are out of hibernation and ready to travel the world. Stack up on
          $MILES as you travel around the metaverse visiting different family members along the way
          in our Play-To-Earn game!
        </p>
      </div>
      <OwlCarousel
        stageClass="flex items-end py-4"
        className="bear-carousel"
        items={3}
        stagePadding={medium ? 150 : small ? 68 : 15}
        loop
        autoplay
        margin={10}
        center
        dots={false}
        responsive={{
          1200: {
            items: 5,
          },
        }}
      >
        {items}
      </OwlCarousel>
    </div>
  );
};

export default TravelBears;
