import React from 'react';
import clsx from 'clsx';

interface IProps {
  className?: string;
  hasShadow?: boolean;
}

const Card: React.FunctionComponent<IProps> = ({ children, hasShadow = false, className }) => {
  return (
    <div
      className={clsx('rounded-[16px]', className, {
        'shadow-[0px_4px_10px_4px_#DEE2E340]': hasShadow,
      })}
      style={{ background: 'linear-gradient(209.54deg, #FFFFFF -24.47%, #FFFFF1 126.66%)' }}
    >
      {children}
    </div>
  );
};

export default Card;
