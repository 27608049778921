import React from 'react';
import clsx from 'clsx';
import './_style.scss';

interface IProps {
  withShadow?: boolean;
  className?: string;
  small?: boolean;
}

const MintButton: React.FunctionComponent<IProps> = ({ withShadow, className, small }) => {
  const onMint = () => {
    // mint functionality
  };

  return (
    <button
      onClick={onMint}
      className={clsx(
        'mint-button bg-yellow h-[48px] w-[216px] text-darkblue font-bold hover:bg-orange duration-100',
        className,
        { 'mint-button--small': small },
      )}
      style={{ boxShadow: withShadow ? '0px 4px 12px 4px rgba(112, 132, 142, 0.25)' : undefined }}
    >
      MINT
    </button>
  );
};
export default MintButton;
