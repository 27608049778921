import React from 'react';

const QuestionIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="142"
      height="52"
      className="question-icon"
      viewBox="0 0 142 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.752 42.1187H16.3018C14.5762 42.1187 13.1738 43.5561 13.1738 45.3249V48.7929C13.1738 50.5617 14.5762 51.9991 16.3018 51.9991H18.752C20.4777 51.9991 21.88 50.5617 21.88 48.7929V45.3249C21.88 43.5508 20.4777 42.1187 18.752 42.1187Z"
        fill="#1B92A6"
      />
      <path
        d="M1.47424 15.2713L5.99387 15.8484C7.42744 16.0301 8.78802 15.1858 9.3041 13.8018C9.95572 12.0544 10.8628 10.6757 12.0253 9.6711C13.6726 8.24968 15.7213 7.53363 18.1713 7.53363C20.7101 7.53363 22.7327 8.21762 24.234 9.58026C25.7353 10.9482 26.4808 12.5834 26.4808 14.5018C26.4808 15.8805 26.0585 17.1416 25.2141 18.2851C24.6667 19.0119 22.9829 20.5402 20.1731 22.8754C17.3581 25.2106 15.4867 27.3159 14.5484 29.1862C13.5892 31.0993 13.2556 33.2207 13.1722 35.3475C13.0992 37.1697 14.5171 38.6873 16.2999 38.6873H18.8543C20.4807 38.6873 21.8257 37.4102 21.9716 35.7536C22.0759 34.5621 22.2688 33.659 22.5608 33.0445C23.0507 31.9917 24.3175 30.6023 26.3505 28.871C30.2863 25.5312 32.851 22.8914 34.0553 20.9463C35.2543 19.0065 35.859 16.9492 35.859 14.7743C35.859 10.8467 34.2273 7.40003 30.9588 4.43963C27.6955 1.47923 23.2957 -0.000976562 17.77 -0.000976562C12.5153 -0.000976562 8.2772 1.45785 5.04517 4.38085C2.29273 6.87101 0.619366 9.82072 0.0303015 13.2247C-0.152204 14.2186 0.509842 15.1484 1.47424 15.2713Z"
        fill="#1B92A6"
      />
      <path
        d="M71.8204 42.1187H69.3701C67.6446 42.1187 66.2422 43.5561 66.2422 45.3249V48.7929C66.2422 50.5617 67.6446 51.9991 69.3701 51.9991H71.8204C73.546 51.9991 74.9483 50.5617 74.9483 48.7929V45.3249C74.9483 43.5508 73.546 42.1187 71.8204 42.1187Z"
        fill="#1B92A6"
      />
      <path
        d="M54.5426 15.2713L59.0622 15.8484C60.4958 16.0301 61.8564 15.1858 62.3725 13.8018C63.0241 12.0544 63.9311 10.6757 65.0936 9.6711C66.741 8.24968 68.7896 7.53363 71.2397 7.53363C73.7784 7.53363 75.801 8.21762 77.3024 9.58026C78.8037 10.9482 79.5492 12.5834 79.5492 14.5018C79.5492 15.8805 79.1269 17.1416 78.2824 18.2851C77.735 19.0119 76.0513 20.5402 73.2415 22.8754C70.4265 25.2106 68.5551 27.3159 67.6168 29.1862C66.6576 31.0993 66.3239 33.2207 66.2405 35.3475C66.1676 37.1697 67.5855 38.6873 69.3683 38.6873H71.9227C73.5491 38.6873 74.894 37.4102 75.04 35.7536C75.1443 34.5621 75.3371 33.659 75.6291 33.0445C76.1191 31.9917 77.3858 30.6023 79.4189 28.871C83.3546 25.5312 85.9194 22.8914 87.1236 20.9463C88.3226 19.0065 88.9273 16.9492 88.9273 14.7743C88.9273 10.8467 87.2957 7.40003 84.0271 4.43963C80.7638 1.47923 76.3641 -0.000976562 70.8384 -0.000976562C65.5837 -0.000976562 61.3456 1.45785 58.1135 4.38085C55.3611 6.87101 53.6877 9.82072 53.0987 13.2247C52.9162 14.2186 53.5782 15.1484 54.5426 15.2713Z"
        fill="#1B92A6"
      />
      <path
        d="M124.893 42.1187H122.442C120.717 42.1187 119.314 43.5561 119.314 45.3249V48.7929C119.314 50.5617 120.717 51.9991 122.442 51.9991H124.893C126.618 51.9991 128.021 50.5617 128.021 48.7929V45.3249C128.021 43.5508 126.618 42.1187 124.893 42.1187Z"
        fill="#1B92A6"
      />
      <path
        d="M107.615 15.2713L112.134 15.8484C113.568 16.0301 114.929 15.1858 115.445 13.8018C116.096 12.0544 117.003 10.6757 118.166 9.6711C119.813 8.24968 121.862 7.53363 124.312 7.53363C126.851 7.53363 128.873 8.21762 130.375 9.58026C131.876 10.9482 132.621 12.5834 132.621 14.5018C132.621 15.8805 132.199 17.1416 131.355 18.2851C130.807 19.0119 129.124 20.5402 126.314 22.8754C123.499 25.2106 121.627 27.3159 120.689 29.1862C119.73 31.0993 119.396 33.2207 119.313 35.3475C119.24 37.1697 120.658 38.6873 122.441 38.6873H124.995C126.621 38.6873 127.966 37.4102 128.112 35.7536C128.217 34.5621 128.409 33.659 128.701 33.0445C129.191 31.9917 130.458 30.6023 132.491 28.871C136.427 25.5312 138.992 22.8914 140.196 20.9463C141.395 19.0065 142 16.9492 142 14.7743C142 10.8467 140.368 7.40003 137.099 4.43963C133.836 1.47923 129.436 -0.000976562 123.911 -0.000976562C118.656 -0.000976562 114.418 1.45785 111.186 4.38085C108.433 6.87101 106.76 9.82072 106.171 13.2247C105.988 14.2186 106.65 15.1484 107.615 15.2713Z"
        fill="#1B92A6"
      />
    </svg>
  );
};

export default QuestionIcon;
