import React from 'react';

const EqualIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="32"
      height="32"
      className="equal-icon"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="12.8" rx="2" fill="#1B92A6" />
      <rect y="19.1992" width="32" height="12.8" rx="2" fill="#1B92A6" />
    </svg>
  );
};
export default EqualIcon;
