import React from 'react';
import Components from 'pages/home-page/components';

const HomePage: React.FunctionComponent = () => {
  return (
    <div className="px-3">
      <Components.FirstViewSection />
      <Components.MintSection />
      <Components.TravelBears />
      <Components.EarnSection />
      <Components.TreasureHunt />
      <Components.RoadMapSection />
      <Components.TeamSection />
      <Components.FooterSection />
    </div>
  );
};
export default HomePage;
