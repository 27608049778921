import React from 'react';

const SecondArrowIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="352"
      height="145"
      viewBox="0 0 352 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M344 136C254.202 53.1021 154.666 7 49.9042 7C35.5 7 21.195 7.8715 7 9.59045"
          stroke="#1B92A6"
          strokeWidth="8"
          strokeDasharray="25 25"
        />
        <circle cx="8" cy="8" r="8" fill="#1B92A6" />
        <circle cx="344" cy="137" r="8" fill="#1B92A6" />
      </g>
    </svg>
  );
};
export default SecondArrowIcon;
