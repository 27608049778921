import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import ArrowDownIcon from 'components/icons/ArrowDownIcon';
import ArrowDownLongIcon from 'components/icons/ArrowDownLongIcon';
import CloseIcon from 'components/icons/CloseIcon';
import { roadmapItems } from '../../data';
import './_styles.scss';

const RoadMapSection: React.FunctionComponent = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 900px)',
  });
  const mobile = useMediaQuery({
    query: '(max-width: 568px)',
  });

  function fadeIn(item: any, delay: number) {
    setTimeout(() => {
      item.classList.add('fadein');
    }, delay);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        const items = document.getElementsByClassName('road-map-section__row');
        for (let i = 0; i < items.length; i += 1) {
          fadeIn(items[i], i * 750);
        }
        observer.disconnect();
      }
    });

    const container = document.getElementById('road-map');
    if (container) {
      observer.observe(container);
    }
  }, []);

  return (
    <div id="road-map" className="road-map-section">
      <p
        className={clsx('text-darkblue font-bold text-[32px]', {
          'text-[48px] leading-[63.5px] ml-[48px]': isDesktop,
          'leading-[42.34px]': !isDesktop,
          'ml-[16px]': mobile,
        })}
      >
        ROADMAP
      </p>
      <div className={clsx('flex justify-start', { 'mt-20': isDesktop, 'mt-10': !isDesktop })}>
        {mobile ? <ArrowDownLongIcon /> : !isDesktop ? <ArrowDownIcon /> : null}
        <div
          className={clsx({
            'ml-20': !isDesktop && !mobile,
            'ml-10': mobile,
            'w-full px-[48px]': isDesktop,
          })}
        >
          {roadmapItems.map(({ icon: Icon, ...item }, index) => {
            const list = (
              <ul
                className={clsx('road-map-section__list', {
                  [item.className || '']: item.className && !isDesktop && !mobile,
                  'mt-[42px]':
                    (mobile && item.key === '2') ||
                    (mobile && item.key === '3') ||
                    (mobile && item.key === '4'),
                  'mt-[64px]': mobile && item.key === '5',
                })}
              >
                <span className="text-darkblue">{item.key}</span>
                {item.items.map((text, idx) => (
                  <li
                    className="w-full max-w-[448px] text-mediumblue font-medium"
                    key={`${item.key}-${idx}`}
                  >
                    {text}
                  </li>
                ))}
              </ul>
            );
            if (index % 2 === 1) {
              return (
                <div key={item.key} className={clsx('w-full road-map-section__row pl-[33px]')}>
                  {list}
                  {isDesktop && <Icon />}
                </div>
              );
            }
            return (
              <div
                key={item.key}
                className={clsx('w-full road-map-section__row justify-end', {
                  'third-row': item.key === '3',
                  'first-row': item.key === '1',
                })}
              >
                {isDesktop && <Icon />}
                {list}
              </div>
            );
          })}
          <div className={clsx('w-full road-map-section__row road-map-section__row--last')}>
            <ul
              className={clsx('road-map-section__list mr-auto', {
                'mt-[78px]': !isDesktop && !mobile,
                'mt-[42px]': mobile,
              })}
            >
              <CloseIcon />
              <li className="w-full max-w-[448px] text-mediumblue font-medium text-[24px]">
                In talks for future travel-related partnerships +collaborations
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RoadMapSection;
//
