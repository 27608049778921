import EarnSection from './earn';
import FirstViewSection from './first-view';
import FooterSection from './footer';
import MintSection from './mint';
import RoadMapSection from './road-map';
import TeamSection from './team';
import TravelBears from './travel-bears';
import TreasureHunt from './treasure-hunt';

export default {
  FirstViewSection,
  MintSection,
  EarnSection,
  TreasureHunt,
  RoadMapSection,
  TravelBears,
  TeamSection,
  FooterSection,
};
