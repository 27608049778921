import React from 'react';

const PlusIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="32"
      className="plus-icon"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.334 10.666C22.2294 10.666 21.334 9.77059 21.334 8.66602V2C21.334 0.895431 20.4386 0 19.334 0H12.6673C11.5627 0 10.6673 0.895431 10.6673 2V8.66602C10.6673 9.77059 9.77189 10.666 8.66732 10.666H2C0.89543 10.666 0 11.5614 0 12.666V19.3327C0 20.4373 0.895428 21.3327 2 21.3327H8.66732C9.77189 21.3327 10.6673 22.2281 10.6673 23.3327V30C10.6673 31.1046 11.5627 32 12.6673 32H19.334C20.4386 32 21.334 31.1046 21.334 30V23.3327C21.334 22.2281 22.2294 21.3327 23.334 21.3327H30C31.1046 21.3327 32 20.4373 32 19.3327V12.666C32 11.5614 31.1046 10.666 30 10.666H23.334Z"
        fill="#1B92A6"
      />
    </svg>
  );
};

export default PlusIcon;
