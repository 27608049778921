import React from 'react';

const FourthArrowIcon: React.FunctionComponent = () => {
  return (
    <svg
      width="352"
      height="98"
      viewBox="0 0 352 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M344 90C254.202 36.6626 154.666 7 49.9042 7C35.5 7 21.195 7.56073 7 8.66673"
          stroke="#1B92A6"
          strokeWidth="8"
          strokeDasharray="25 25"
        />
        <circle cx="8" cy="8" r="8" fill="#1B92A6" />
        <circle cx="344" cy="90" r="8" fill="#1B92A6" />
      </g>
    </svg>
  );
};
export default FourthArrowIcon;
