import React from 'react';

const MenuIcon: React.FunctionComponent = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.9998 22.6665C28.7362 22.6665 29.3332 23.2635 29.3332 23.9998C29.3332 24.7362 28.7362 25.3332 27.9998 25.3332H3.99984C3.26346 25.3332 2.6665 24.7362 2.6665 23.9998C2.6665 23.2635 3.26346 22.6665 3.99984 22.6665H27.9998ZM27.9998 14.6665C28.7362 14.6665 29.3332 15.2635 29.3332 15.9998C29.3332 16.7362 28.7362 17.3332 27.9998 17.3332H3.99984C3.26346 17.3332 2.6665 16.7362 2.6665 15.9998C2.6665 15.2635 3.26346 14.6665 3.99984 14.6665H27.9998ZM27.9998 6.6665C28.7362 6.6665 29.3332 7.26346 29.3332 7.99984C29.3332 8.73622 28.7362 9.33317 27.9998 9.33317H3.99984C3.26346 9.33317 2.6665 8.73622 2.6665 7.99984C2.6665 7.26346 3.26346 6.6665 3.99984 6.6665H27.9998Z"
        fill="#fdcb4b"
      />
    </svg>
  );
};

export default MenuIcon;
