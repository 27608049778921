import React from 'react';
import HomePage from 'pages/home-page';
import Navbar from 'components/layouts/navbar';
import AppProvider from 'components/providers/App';
import 'assets/sass/app.scss';

export const App: React.FunctionComponent = () => {
  return (
    <AppProvider>
      <div className="app">
        <Navbar />
        <HomePage />
      </div>
    </AppProvider>
  );
};

export default App;
