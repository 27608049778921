import React from 'react';
import './_styles.scss';
import Logo from 'components/icons/Logo';

const FooterSection: React.FunctionComponent = () => {
  const imgSrc = 'images/footer.gif';

  return (
    <div
      id="footer"
      className="footer-section my-12 w-full mx-auto max-w-[1144px] rounded-[32px] overflow-hidden"
    >
      <img className="object-cover w-full" src={imgSrc} alt="footer" />
      <div className="footer-section__text">
        <Logo />
        <p>© 2021 TRAVEL BEARS ALL RIGHTS RESERVED.</p>
      </div>
    </div>
  );
};

export default FooterSection;
